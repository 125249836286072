export const contentRulesTileGrid = [
  {
    icon: 'block-5-1',
    title: 'Young models',
    value: 'Advertisers should be able to provide IDs and 2257s if their ads display underage models. Child pornography is prohibited!'
  },
  {
    icon: 'block-5-2',
    title: 'Pharmaceuticals',
    value:
      'The phrase “Consult your doctor” must be displayed on landing pages. If you use branded names like Viagra, Cialis etc., words like “Generic”, “Like Viagra” etc. must be visible on the banner'
  },
  {
    icon: 'block-5-3',
    title: 'Cartoon ads',
    value: 'Display of all Disney characters and underage characters (e.g. young Lisa Simpson, underage Bart) is prohibited'
  },
  {
    icon: 'block-5-4',
    title: 'Substances',
    value: 'Promotion and images of drugs, alcohol and related attributes aren’t allowed'
  },
  {
    icon: 'block-5-5',
    title: 'Prohibited acts',
    value:
      'Rape, abuse, blood, violence, bestiality, extreme, crying, mutilation, death, brutality, torture, urination, incest, coprophilia, incapacitation, necrophilia are strictly prohibited'
  }
];
