import { Routes } from '@angular/router';
import { HelpLayoutComponent } from './layout/help.layout';
import { MainGuard } from '../../core/guard/main.guard';
import { HELP_ROUTE } from '../../core/routes';
import { TradeDeskComponent } from './components/trade-desk/trade-desk.component';
import { ForAdvertisersComponent } from './components/for-advertisers/for-advertisers.component';
import { ConversionsImportServiceComponent } from './components/conversions-import-service/conversions-import-service.component';

export const routes: Routes = [
  {
    path: '',
    component: HelpLayoutComponent,
    canActivateChild: [MainGuard],
    children: [
      {
        path: '',
        redirectTo: HELP_ROUTE.tradingDeskHelp,
        pathMatch: 'full'
      },
      {
        path: HELP_ROUTE.tradingDeskHelp,
        component: TradeDeskComponent,
        title: 'Help • Trading desk',
        data: {
          pageType: 'TRADE_HELP'
        }
      },
      {
        path: HELP_ROUTE.forAdvertisersHelp,
        component: ForAdvertisersComponent,
        title: 'Help • For advertisers',
        data: {
          pageType: 'ADV_HELP'
        }
      },
      {
        path: HELP_ROUTE.conversionsImportService,
        component: ConversionsImportServiceComponent,
        title: 'Help • Conversions import service',
        data: {
          pageType: 'CONV_HELP'
        }
      }
    ]
  }
];
