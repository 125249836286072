<section class="trading-desk">
  <div class="guidelines">
    <h2 class="guidelines__h2 text-center">Hierarchy of the entities in the trading desk</h2>
    <p class="text-center">Project -> Campaign -> Creative</p>

    <h2
      class="guidelines__h2 text-center"
      id="chapter-1"
    >
      Where can I see my funds in the account?
    </h2>
    <p class="text-center">In the upper right corner of the screen.</p>

    <img
      src="/assets/images/guidelines/trade-desk/1.jpg"
      class="guidelines__screenshot"
      alt="Where can I see my funds in the account? In the upper right corner of the screen."
    />

    <h2
      class="guidelines__h2 text-center"
      id="chapter-2"
    >
      How to create a project?
    </h2>

    <p class="text-center">Click the &laquo;Trade desk&raquo; button to go to the trading desk:</p>

    <img
      src="/assets/images/guidelines/trade-desk/2.jpg"
      class="guidelines__screenshot"
      alt="How to create a project? Click the Trade desk button to go to the trading desk"
    />

    <p class="text-center">
      Now you are on the trading desk. Here you can create your projects, add campaigns to the projects and add creatives to your campaigns.
    </p>

    <p class="text-center">
      Click the &laquo;Plus&raquo; button in the upper right corner of the screen to create a new project. Type your project name:
    </p>

    <img
      src="/assets/images/guidelines/trade-desk/3.jpg"
      class="guidelines__screenshot"
      alt="Then click create. Your project was created."
    />

    <p class="text-center">Then click &laquo;create&raquo;. Your project was created.</p>

    <h2
      class="guidelines__h2 text-center"
      id="chapter-3"
    >
      How to view a created project?
    </h2>
    <p class="text-center">
      Just click the project name. You can see your project details, like status of the project and campaigns on the right tab.
    </p>

    <img
      src="/assets/images/guidelines/trade-desk/4.jpg"
      class="guidelines__screenshot"
      alt="How to view a created project? Just click the project name. You can see your project details, like status of the project and campaigns on the right tab."
    />
    <p class="text-center">To go back please click in any free place.</p>

    <h2
      class="guidelines__h2 text-center"
      id="chapter-4"
    >
      How to create a campaign?
    </h2>
    <p class="text-center">Go to any project and click &laquo;plus&raquo; button next to campaigns block:</p>

    <img
      src="/assets/images/guidelines/trade-desk/5.jpg"
      class="guidelines__screenshot"
      alt="How to create a campaign? Go to any project and click plus button next to campaigns block"
    />

    <p class="text-center">Else you can go to campaigns tab and click &laquo;plus&raquo; button:</p>

    <img
      src="/assets/images/guidelines/trade-desk/6.jpg"
      class="guidelines__screenshot"
      alt="Else you can go to campaigns tab and click &laquo;plus&raquo; button"
    />

    <p class="text-center">In the campaign creating interface go through the four steps one by one:</p>

    <img
      src="/assets/images/guidelines/trade-desk/7.jpg"
      class="guidelines__screenshot"
      alt="Else you can go to campaigns tab and click &laquo;plus&raquo; button"
    />

    <p class="text-center">
      1. Set your bid (CPM in USD) and frequency cap. Type your campaign name and choose your campaign media type and advert format. Be
      careful, you can`t change them later:
    </p>

    <img
      src="/assets/images/guidelines/trade-desk/8.jpg"
      class="guidelines__screenshot"
      alt="Set your bid (CPM in USD) and frequency cap. Type your campaign name and choose your campaign media type and advert format. Be careful, you can`t change them later"
    />

    <p class="text-center">2. Paste your click URL with parameters:</p>

    <img
      src="/assets/images/guidelines/trade-desk/9.jpg"
      class="guidelines__screenshot"
      alt="Set your bid (CPM in USD) and frequency cap. Type your campaign name and choose your campaign media type and advert format. Be careful, you can`t change them later"
    />

    <p class="text-center">3. Set up the advanced targetings. If you don't choose any all the items will be selected automatically.</p>

    <img
      src="/assets/images/guidelines/trade-desk/10.jpg"
      class="guidelines__screenshot"
      alt="Set your bid (CPM in USD) and frequency cap. Type your campaign name and choose your campaign media type and advert format. Be careful, you can`t change them later"
    />

    <p class="text-center">
      4. Set up the activity schedule for your campaign. Blue color means &laquo;on&raquo;, gray color means &laquo;off&raquo;. You can
      click on hours and days to activate or deactivate them:
    </p>

    <img
      src="/assets/images/guidelines/trade-desk/11.jpg"
      class="guidelines__screenshot"
      alt="4. Set up the activity schedule for your campaign. Blue color means &laquo;on&raquo;, gray color means &laquo;off&raquo;.  You can click on hours and days to activate or deactivate them"
    />

    <p class="text-center">Click the &laquo;save&raquo; button to finish creating the campaign.</p>

    <h2
      class="guidelines__h2 text-center"
      id="chapter-5"
    >
      How to change the settings in your campaign?
    </h2>

    <p class="text-center">Go to your campaign settings simply clicking on it:</p>

    <img
      src="/assets/images/guidelines/trade-desk/12.jpg"
      class="guidelines__screenshot"
      alt="Go to your campaign settings simply clicking on it"
    />

    <p class="text-center">Click "campaign settings":</p>

    <p class="text-center">
      Now you are in the same interface where you have set up the settings when you created the campaign. Just change the settings you like
      and click the same &laquo;save&raquo; button.
    </p>

    <h2
      class="guidelines__h2 text-center"
      id="chapter-6"
    >
      How to add a creative to your campaign?
    </h2>

    <p class="text-center">Go to your campaign and press &laquo;plus&raquo; button:</p>

    <img
      src="/assets/images/guidelines/trade-desk/13.jpg"
      class="guidelines__screenshot"
      alt="Go to your campaign and press &laquo;plus&raquo; button"
      loading="lazy"
    />

    <p class="text-center">Upload creative files and type their names:</p>

    <img
      src="/assets/images/guidelines/trade-desk/14.jpg"
      class="guidelines__screenshot"
      alt="Upload creative files and type their names"
    />

    <p class="text-center">Click &laquo;add creatives&raquo; and wait for their status to become &laquo;approved&raquo;.</p>

    <h2
      class="guidelines__h2 text-center"
      id="chapter-7"
    >
      How to launch your campaigns and start getting traffic?
    </h2>

    <p class="text-center">Make sure you enable:</p>

    <p class="text-center">1. Your project:</p>

    <img
      src="/assets/images/guidelines/trade-desk/15.jpg"
      class="guidelines__screenshot"
      alt="Your project"
      loading="lazy"
    />

    <p class="text-center">2. Campaigns in your project:</p>

    <img
      src="/assets/images/guidelines/trade-desk/16.jpg"
      class="guidelines__screenshot"
      alt="Campaigns in your project"
      loading="lazy"
    />

    <p class="text-center">3. Creatives in your campaigns:</p>

    <img
      src="/assets/images/guidelines/trade-desk/17.jpg"
      class="guidelines__screenshot"
      alt="Creatives in your campaigns"
      loading="lazy"
    />

    <h2
      class="guidelines__h2 text-center"
      id="chapter-8"
    >
      How to check statistics of your campaigns?
    </h2>

    <p class="text-center">
      Open the &laquo;Reports&raquo; tab and choose your campaign in the filters on the right. You also can see full statistics of your
      campaigns and break it down by campaigns (and all targeting).
    </p>

    <img
      src="/assets/images/guidelines/trade-desk/18.jpg"
      class="guidelines__screenshot"
      alt="Open the &laquo;Reports&raquo; tab  and choose your campaign in the filters on the right. You also can see full statistics of your campaigns and break it down by campaigns (and all targeting)."
    />
  </div>

  <ul class="list-of-contents">
    @for (item of listOfContents; track item) {
    <li
      class="list-of-contents__item"
      (click)="goTo(item.id)"
    >
      {{ item.value }}
    </li>
    }
  </ul>
</section>
