import { Component, inject, OnInit } from '@angular/core';
import { ViewportScroller } from '@angular/common';

@Component({
  selector: 'adxad-trade-desk',
  templateUrl: './trade-desk.component.html',
  styleUrls: ['./trade-desk.component.scss']
})
export class TradeDeskComponent {
  private scroller = inject(ViewportScroller);

  listOfContents = [
    {
      id: 'chapter-1',
      value: 'Where can I see my funds in the account?'
    },
    {
      id: 'chapter-2',
      value: 'How to create a project?'
    },
    {
      id: 'chapter-3',
      value: 'How to view a created project?'
    },
    {
      id: 'chapter-4',
      value: 'How to create a campaign?'
    },
    {
      id: 'chapter-5',
      value: 'How to change the settings in your campaign?'
    },
    {
      id: 'chapter-6',
      value: 'How to add a creative to your campaign?'
    },
    {
      id: 'chapter-7',
      value: 'How to launch your campaigns and start getting traffic?'
    },
    {
      id: 'chapter-8',
      value: 'How to check statistics of your campaigns?'
    }
  ];

  goTo(target: string): void {
    this.scroller.setOffset([0, 100]);
    this.scroller.scrollToAnchor(target);
  }
}
