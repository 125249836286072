import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ForAdvertisersComponent } from './components/for-advertisers/for-advertisers.component';
import { GuiModule } from '../../gui/gui.module';
import { TradeDeskComponent } from './components/trade-desk/trade-desk.component';
import { ConversionsImportServiceComponent } from './components/conversions-import-service/conversions-import-service.component';
import { HelpLayoutComponent } from './layout/help.layout';
import { RouterModule } from '@angular/router';
import { routes } from './help.routes';

const ENTRY_MODULES = [GuiModule, CommonModule];

const ENTRY_COMPONENTS = [HelpLayoutComponent, ForAdvertisersComponent, TradeDeskComponent, ConversionsImportServiceComponent];

@NgModule({
  declarations: [...ENTRY_COMPONENTS],
  imports: [...ENTRY_MODULES, RouterModule.forChild(routes)]
})
export class HelpModule {}
