<div
  [class.--menu-opened]="this.layoutService.isMenuStaticOpened"
  class="adxad-main-container"
>
  <adxad-tabs-nav>
    <adxad-tab-link
      (click)="globica.trackEventGoals(tab.globicaKey)"
      *ngFor="let tab of tabs"
      [routerLink]="tab.link"
      routerLinkActive="active"
    >
      {{ tab.name | transloco }}
    </adxad-tab-link>
  </adxad-tabs-nav>

  <router-outlet></router-outlet>
</div>
