export const regulationsTileGrid = [
  {
    icon: 'block-3-1',
    value: 'All ads considered to have a negative effect on our network can be paused at any time'
  },
  {
    icon: 'block-3-2',
    value: 'All banners and landings can be paused if they violate copyright, patent, trademark, intellectual property laws'
  },
  {
    icon: 'block-3-3',
    value:
      'All banners and landings that copy any elements of the Specific Trademarks like Facebook, Tinder, Skype, Whatsapp, Snapchat etc. are not allowed'
  },
  {
    icon: 'block-3-4',
    value: 'All illegal activities (hacking, password stealing, illegal gambling, drug/weapon trafficking) are not allowed'
  },
  {
    icon: 'block-3-5',
    value:
      'All creatives containing misleading; malware, spyware, adware piracy or stolen content, “tech support” offers and creatives with no clickable links are prohibited'
  }
];

export const regulationsList = [
  {
    icon: 'block-2-1',
    value: 'All files must not be bigger than 300 kB'
  },
  {
    icon: 'block-2-2',
    value: 'All animated images and texts must be shown for 2 seconds minimum'
  },
  {
    icon: 'block-2-3',
    value: 'JPG, PNG, GIF files are acceptable'
  },
  {
    icon: 'block-2-4',
    value: 'All links/landings must open in a new window/tab'
  }
];
