export const misleadingTileGrid = [
  {
    icon: 'block-4-1',
    value: 'Aren’t related to the landing page'
  },
  {
    icon: 'block-4-2',
    value: 'Contain misleading exit buttons (“x” icon, “cancel”, ”skip” buttons) and check boxes or drop down menus'
  },
  {
    icon: 'block-4-3',
    value: 'Show fake virus alerts, calls/messages, video players'
  },
  {
    icon: 'block-4-4',
    value: 'Imitate Microsoft Windows or mobile phone elements'
  },
  {
    icon: 'block-4-5',
    value: 'Ask disclaimer questions like “are you 18+?”'
  },
  {
    icon: 'block-4-6',
    value: 'Display words “illegal”, “banned”, “forbidden” (in any language)'
  }
];
