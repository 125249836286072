import { Component } from '@angular/core';
import { HELP_ROUTE } from '../../../core/routes';
import { GlobicaService } from '../../../core/services/globica.service';
import { LayoutService } from '../../../layout/layout.service';

@Component({
  selector: 'adxad-help.layout',
  templateUrl: './help.layout.html',
  styleUrls: ['./help.layout.scss']
})
export class HelpLayoutComponent {
  public tabs = [
    {
      name: 'menu_tradeDeskHelp',
      stage: 'tradeDeskHelp',
      link: './' + HELP_ROUTE.tradingDeskHelp,
      globicaKey: 'linkMenuTadingDeskHelp_click'
    },
    {
      name: 'menu_forAdvertisersHelp',
      stage: 'forAdvertisersHelp',
      link: './' + HELP_ROUTE.forAdvertisersHelp,
      globicaKey: 'linkMenuForAdvertisersHelp_click'
    },
    {
      name: 'menu_conversionsImportService',
      stage: 'conversionsImportService',
      link: './' + HELP_ROUTE.conversionsImportService,
      tooltip: 'tooltips_tabProjects',
      globicaKey: 'linkMenuConversionsImportService_click'
    }
  ];

  constructor(
    public globica: GlobicaService,
    public layoutService: LayoutService
  ) {}
}
