<section class="trading-desk">
  <p class="guidelines__description">
    We provide our clients an opportunity to postback any actions into our system for more effective campaign optimization.
  </p>

  <h2 class="guidelines__h2 text-center">The service accepts three GET parameters:</h2>

  <ol class="num-list">
    <li>clickid - it can be received via &lbrace; clickId &rbrace; macros from our system and should be forwarded back to our system.</li>
    <li>payout - the conversion value in $, fractional number is possible, separator is a dot. For example: 1.5.</li>
    <li>
      type - conversion type, three options are available: goal1, goal2, goal3. User can make 3 different conversion types which will be
      forwarded up. Example: registration -> goal1; sale ->goal 2, etc.
    </li>
  </ol>

  <p>To import all conversions, they should be sent to the domain http://conv.adxadserv.com/ using the GET method.</p>
  <p>An example of a complete request:</p>
  <p>http://conv.adxadserv.com/?clickid=d94ff74f-ea02-4153-ac04-111119!44117&payout=1&type=goal3</p>

  <h2 class="guidelines__h2 text-center">How to set up.</h2>

  <p>
    <b>Step 1: Adding ClickID token of ADxAD to the traffic source template in a tracking system.</b>
  </p>

  <p>&lbrace; clickid &rbrace; token should be added to the template and set equal to any token of your tracking system:</p>

  <img
    src="/assets/images/conversions/screen-1.jpg"
    class="guidelines__screenshot"
    alt="&lbrace; clickid &rbrace; token should be added to the template and set equal to any token of your tracking system:"
  />

  <p>Now ADXAD token can be used.</p>

  <p>
    <b> Step 2: Receive ADxAD ClickID to your tracking system. </b>
  </p>

  <p>&lbrace; clickid &rbrace; token should be added to your click url in the ADxAD campaign settings:</p>

  <img
    src="/assets/images/conversions/screen-2.jpg"
    class="guidelines__screenshot"
    alt="&lbrace; clickid &rbrace; token should be added to your click url in the ADxAD campaign settings:"
  />

  <p>
    Now after a click happens, ADxAD system will replace the token with the internal clickid and your tracking system will be able to
    recognize the click ID of the external system.
  </p>

  <p>
    <b> Step 3: Setting up the postback url. </b>
  </p>

  <p>ADxAD postback url should be added to the postback section of your tracking system.</p>

  <h2 class="guidelines__h2 text-center">ADxAD postback url:</h2>

  <p>http://conv.adxadserv.com/?clickid=%insertyourtoken%&payout=1&type=goal3</p>

  <p>
    %insertyourtoken% should be replaced with the token of your tracking system, which will forward ADxAD clickID into the ADxAD postback
    URL.
  </p>
  <p>Payout = put here a value of a single conversion which will be postbacked.</p>
  <p>Type = put here the type of conversion (goal1/ goal2/ goal3 are the possible options).</p>
  <p>How a ready-to-go integration should look like on the tracking system side:</p>

  <img
    src="/assets/images/conversions/screen-3.jpg"
    class="guidelines__screenshot"
    alt="How a ready-to-go integration should look like on the tracking system side:"
  />
  <p>The integration is done.</p>
</section>
