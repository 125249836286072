import { Component, OnInit } from '@angular/core';
import { generalRequirements } from './data/general-requirements';
import { regulationsTileGrid, regulationsList } from './data/regulations';
import { misleadingTileGrid } from './data/misleading';
import { contentRulesTileGrid } from './data/content-rules';
import { ViewportScroller } from '@angular/common';

@Component({
  selector: 'adxad-for-advertisers',
  templateUrl: './for-advertisers.component.html',
  styleUrls: ['./for-advertisers.component.scss']
})
export class ForAdvertisersComponent implements OnInit {
  generalRequirements = generalRequirements;
  regulationsTileGrid = regulationsTileGrid;
  regulationsList = regulationsList;
  misleadingTileGrid = misleadingTileGrid;
  contentRulesTileGrid = contentRulesTileGrid;

  constructor(private scroller: ViewportScroller) {}

  ngOnInit(): void {
    this.scroller.scrollToPosition([0, 0]);
  }
}
