<section class="guidelines">
  <p class="guidelines__description">For a smooth work, please follow the rules and conditions</p>
  <h2 class="guidelines__h2 --with-dots text-center">General <b>requirements</b></h2>
  <p class="text-center">The ad creatives/landing pages should have:</p>

  <ul class="guidelines__grid">
    <li
      class="guidelines__grid-item col-lg-4 col-md-6 col-sm-12"
      *ngFor="let item of generalRequirements"
    >
      <img
        class="item-icon"
        src="/assets/images/guidelines/{{ item.icon }}.png"
        alt="{{ item.value }}"
      />
      <div class="item-text">{{ item.value }}</div>
    </li>
  </ul>

  <h2 class="guidelines__h2 --with-dots text-center">Regulations</h2>

  <ul class="guidelines__list">
    <li
      class="guidelines__list-item"
      *ngFor="let item of regulationsList"
    >
      <div class="item-counter"></div>
      <img
        class="item-icon"
        src="/assets/images/guidelines/{{ item.icon }}.png"
        alt="{{ item.value }}"
      />
      <div class="item-text">{{ item.value }}</div>
      <img
        class="item-icon__check"
        src="/assets/images/guidelines/check-icon.png"
        alt=""
      />
    </li>
  </ul>

  <ul class="guidelines__grid">
    <li
      class="guidelines__grid-item col-lg-6 col-md-6 col-sm-12"
      *ngFor="let item of regulationsTileGrid"
    >
      <img
        class="item-icon"
        src="/assets/images/guidelines/{{ item.icon }}.png"
        alt="{{ item.value }}"
      />
      <div class="item-text">{{ item.value }}</div>
    </li>
  </ul>

  <div class="guidelines__cite">
    <div class="cite-text">All ads have to get an approval before being published</div>
  </div>

  <h2 class="guidelines__h2 --with-dots text-center">Misleading <b>ADS</b></h2>

  <p class="text-center">All misleading or deceptive ads will be rejected<br />Ads and banners defined as misleading:</p>

  <ul class="guidelines__grid">
    <li
      class="guidelines__grid-item col-lg-6 col-md-6 col-sm-12"
      *ngFor="let item of misleadingTileGrid"
    >
      <img
        class="item-icon"
        src="/assets/images/guidelines/{{ item.icon }}.png"
        alt="{{ item.value }}"
      />
      <div class="item-text">{{ item.value }}</div>
    </li>
  </ul>

  <h2 class="guidelines__h2 --with-dots text-center">Content <b>rules</b></h2>

  <ul class="guidelines__grid">
    <li
      class="guidelines__grid-item col-lg-6 col-md-6 col-sm-12"
      *ngFor="let item of contentRulesTileGrid"
    >
      <img
        class="item-icon"
        src="/assets/images/guidelines/{{ item.icon }}.png"
        alt="{{ item.value }}"
      />
      <div class="item-text">
        <div class="item-title">{{ item.title }}</div>
        {{ item.value }}
      </div>
    </li>
  </ul>
</section>
