export const generalRequirements = [
  {
    icon: 'block-1-1',
    value: 'No imitation of our site in any form (colors, layouts, template etc.)'
  },
  {
    icon: 'block-1-2',
    value: 'No visual portrayal of sexual activity by people (real or imaginary) who are under 18 y.o.'
  },
  {
    icon: 'block-1-3',
    value: 'No campaigns with underage (or lookalike) performers'
  },
  {
    icon: 'block-1-4',
    value: 'No popunders or popups with automatic audio playback'
  },
  {
    icon: 'block-1-5',
    value: 'No malware distribution'
  },
  {
    icon: 'block-1-6',
    value: 'No JavaScript code opening pop-ups that appear on top of the user’s screen'
  },
  {
    icon: 'block-1-7',
    value: 'No JavaScript that causes mobile vibration'
  },
  {
    icon: 'block-1-8',
    value:
      'No redirects to sites with domains like .accountant, .bid, .club, .cricket, .date, .download, .faith, .loan, .party, .vpw, .review, .science, .trade, .uno, .webcam, .win, .xyz'
  },
  {
    icon: 'block-1-9',
    value: 'No products with automatic downloads'
  },
  {
    icon: 'block-1-10',
    value: 'No auto-redirects to mobile app stores'
  }
];
